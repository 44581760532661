<template>
  <div class="student-messages-page">
    <v-row justify="center" no-gutters>
      <v-col md="5">
        <v-card class="my-2" :elevation="$vuetify.breakpoint.mdAndDown ? 0 : 2">
          <intract-smart-list
            paginated
            :handle-click="handleClick"
            :endpoint="studentEndpoint"
            :item-options="{
              title: 'full_name',
              subtitle: 'username',
              avatar: 'image',
              handleClick: handleClick,
            }"
            @updateGetItemsFunction="(f) => (getItemsFunction = f)"
          >
          </intract-smart-list>
          <!-- <v-list>
            <v-list-item v-for="student in allStudents" :key="student.id" :to="{name: 'IndividualStudentMessages', params: {studentId: student.id}}">
              <v-list-item-avatar>
                <v-img :src="student.image"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="subtitle-2">
                {{ student.full_name }}
              </v-list-item-content>
              <v-list-item-icon><v-icon>mdi-arrow-right</v-icon></v-list-item-icon>
            </v-list-item>
          </v-list> -->
        </v-card>
      </v-col>
    </v-row>

    <!-- <infinite-loading
      ref="student-messages-list-loader"
      spinner="waveDots"
      :identifier="infiniteIdentifier"
      @infinite="getAllStudents"
    ></infinite-loading> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import api from "@api/index";
import endpoints from "@api/repository";
import Helper from "@utils/misc";
// import InfiniteLoading from "vue-infinite-loading";
import IntractSmartList from "@components/generics/IntractSmartList";
export default {
  name: "StudentMessagesList",
  props: {
    room: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    // InfiniteLoading,
    IntractSmartList,
  },
  data() {
    return {
      getItemsFunction: null,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "isLoading", "accessToken"]),
    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },

    studentEndpoint() {
      if (!this.room) return null;
      return Helper.addUrlParams(
        endpoints.studentViewSet,
        "room=" + this.room.id
      );
    },
  },
  methods: {
    ...mapActions(["setLoading", "showSnackbar"]),
    async handleClick(student) {
      this.$router.push({
        name: "IndividualStudentMessages",
        params: { studentId: student.id, student: student },
      });
    },

    // async getAllStudents(state) {
    //   console.log("getAllStudents", this.room);
    //   if (!this.room) return;
    //   if (!state) {
    //     //refresh
    //     this.pagination.nextUrl = this.pagination.count = null;
    //     this.posts = [];
    //     this.firstLoad = true;

    //     // this.infiniteIdentifier = new Date().toString();
    //     this.infiniteIdentifier = new Date().toString();
    //     return;
    //     // return;
    //   }
    //   // if (this.isMoreLoading) return;
    //   // if (loadMore && !this.pagination.nextUrl) return;
    //   var url = this.firstLoad
    //     ? Helper.addUrlParams(endpoints.studentViewSet, "room=" + this.room.id)
    //     : this.pagination.nextUrl;
    //   var response = await api.call(this.essentials, url);
    //   if (response) {
    //     this.firstLoad = false;
    //     this.allStudents.push(...response.results);
    //     this.pagination.nextUrl = response.next;
    //     this.pagination.count = response.count;
    //     if (state) {
    //       if (this.allStudents.length == response.count) state.complete();
    //       else state.loaded();
    //     }
    //   }
    // },
    async handleApiError(err) {
      this.moreLoading = false;
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.showSnackbar({ title: "Something went wrong", type: "error" });
      }
    },
  },
  created() {
    // if (this.room) this.getAllStudents();
  },
  watch: {
    room() {
      console.log("room changed");
      if (this.room) this.getItemsFunction();
    },
  },
};
</script>